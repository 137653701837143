//React
import * as React from 'react';
import { Fragment } from 'react';

//Gatsby
import { Link } from 'gatsby';

//Styles
import * as styles from './SideDrawer.module.scss';

//Images
import logo from '../../../images/icon.png';

const SideDrawer = ({ drawerOpen, toggleDrawer }) => {
    return (
        <Fragment>
        <div className={`${styles.drawer} ${drawerOpen ? styles.drawerOpen : ''}`}>
            <Link to={'/'} className= {styles.logoWrapper} onClick={toggleDrawer}>
            <div></div>
            <img src={logo} alt="DK Logo" />
            </Link>
       
            <nav>
               
                <Link to={'/'} onClick={toggleDrawer}>Home</Link>
                <Link to={'/my-work'} onClick={toggleDrawer}>My Work</Link>
                <Link to={'/about'} onClick={toggleDrawer}>About Me</Link>
                <Link to={'/contact'} onClick={toggleDrawer}>Contact</Link>
            </nav>
        </div>
        <div className={`${styles.backdrop} ${drawerOpen ? styles.showBackdrop : ''}`} onClick={toggleDrawer}></div>
        </Fragment>
    )
}

export default SideDrawer;
