//React
import * as React from 'react';
import { useState} from 'react';

//Styles
import * as styles from './Lightbox.module.scss';

const Lightbox = ({ show, src, closeLightbox }) => {
    const [showImage, setShowImage] = useState(false);


    const handleClose = ()=>{
        setShowImage(false); 
        closeLightbox();
    }
    const fadeInImage = ()=>{
        setShowImage(true);
    }
   
    return (
        <div className={`${styles.lightbox} ${show ? styles.show : ''}`}>
            <img className={showImage ? styles.showImage : ''} src={src} alt="Test" onLoad={fadeInImage} />
            <div className={styles.background} onClick={handleClose}></div>
            <div className={styles.exit} onClick={handleClose}>
                <div></div>
                <div></div>
            </div>
        </div>
    )

}

export default Lightbox;