//React
import * as React from 'react';
import { useState } from 'react';

//Styles
import * as styles from './Navigation.module.scss';

//Gatsby
import {navigate} from 'gatsby';
import { Link } from 'gatsby';

//Components
import Hamburger from './Hamburger/Hamburger';
import SideDrawer from './SideDrawer/SideDrawer';
// import BackButton from './BackButton/BackButton';

//Images
import logo from '../../images/DK-logo.svg';


const Navigation = ({location}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = ()=>{
        setDrawerOpen((prevState)=> !prevState);
    }
    // const goBack = ()=> {
    //     navigate('/my-work')
    // }    
    return (
        <div>
            <Link className={styles.homeLogo} to={'/'}>
                <img src={logo} alt="DK Logo" />
            </Link>
            {/* {location.pathname.match(/\/my-work\//) && <BackButton goBack={goBack}/>} */}
            <Hamburger drawerOpen={drawerOpen} toggleDrawer={toggleDrawer}/>
            <SideDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer}/>
        </div>
    )
}

export default Navigation;