//React
import * as React from 'react';

//Styles
import * as styles from './Layout.module.scss';

//Components
import Navigation from '../Navigation/Navigation';
import GoToTop from '../GoToTop/GoToTop';
import Background from '../Background/Background';

const Layout = ({children, location})=>{
    return (
        <div className={styles.fullHeight}>
           <Navigation location={location}/>
           {/* <CubeOverlay/> */}
            {children}
            <Background location={location}/>
            <GoToTop/> 
        </div>
    )
}

export default Layout;