//React
import * as React from 'react';

//Styles
import * as styles from './Hamburger.module.scss';

const Hamburger = ({drawerOpen, toggleDrawer})=>{
    return (
        <div className={`${styles.burger} ${drawerOpen ? styles.burgerOpen : ''}`} onClick={toggleDrawer}>
            <div className={styles.burgerLine}></div>
            <div className={styles.burgerLine}></div>
            <div className={styles.burgerLine}></div>
        </div>


    )
}

export default Hamburger;