//React
import * as React from 'react';

//Styles
import * as styles from './Background.module.scss';

const Background = ({ location }) => {
    const { pathname } = location;
    let backgroundColor = '';
    switch (pathname) {
        case pathname.match(/about/)?.input:
            backgroundColor = 'radial-gradient(white, rgb(206, 166, 106))';
            break;
        case pathname.match(/my-work/)?.input:
            backgroundColor = 'radial-gradient(white, rgb(24, 24, 24))';
            break;
        case pathname.match(/contact/)?.input:
            backgroundColor = 'radial-gradient(white, rgb(87, 91, 165)';
            break;
        case pathname.match(/surprise/)?.input:
            backgroundColor = 'radial-gradient(white, rgb(78, 78, 78))';
            break;
        default:
            backgroundColor = 'radial-gradient(white,  rgb(55, 56, 83))';
    }
    
    return (
        <div className={styles.background} style={{ background: backgroundColor }}></div>
    )
}

export default Background;