//React
import * as React from 'react';
import { useState } from 'react';

//Gatsby
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from 'gatsby';

//Styles
import * as styles from './surprise.module.scss';

//Components
import Layout from '../../components/Layout/Layout';
import Lightbox from '../../components/Lightbox/Lightbox';
import Card from '../../components/Card/Card';
import PageContainer from '../../components/PageContainer/PageContainer';

const Surprise = ({ data, location }) => {
  const [lightboxSrc, setLightboxSrc] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const toggleLightbox = (event) => {
    if (!lightboxOpen) {
      setLightboxOpen(true);
      setLightboxSrc(event.target.src);
    }
    else {
      setLightboxOpen(false);
      setTimeout(() => {

        setLightboxSrc(null)
      }, 300)
    }
  }
  let imageId = -1;
  const imageElements = data.allFile.edges.map(({ node }) => {
    imageId++;

    return (
      <div  key={`Dog Pic ${imageId}`} onClick={toggleLightbox }>
        <GatsbyImage
          className={'dawgs'}
          image={node.childImageSharp.gatsbyImageData}
          alt={`Dog Pic ${imageId}`}
          
         
        />
      </div>
    )
  })


  return (
    <Layout location={location}>
      <main className={styles.Surprise} id='dawgs-page'>
        <PageContainer>
          <Card addedClasses={[styles.dawgCard]}>
            <h1>&#128054; I hope you like dogs... &#128054;</h1>
            <p>Couldn't resist the allure of the unknown, huh?
              Don't worry -- you've been rewarded for your exploration.
              My day job for the last few years has been as a dog walker,
              so I've got thousands of dog pics to share.
              Nothing else to see here... enjoy!</p>
          </Card>
          <div className={styles.imageGrid}>
            {imageElements}
          </div>

        </PageContainer>
      </main>
      <Lightbox show={lightboxOpen} src={lightboxSrc} closeLightbox={toggleLightbox} />
    </Layout>
  )
}

export const pageQuery = (graphql`
    query MyQuery {
        allFile(filter: {relativeDirectory: {eq: "dawgs"}}, limit: 100) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.5625, width: 500)
              }
            }
          }
        }
      }
      
    `)

export default Surprise;