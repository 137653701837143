//React
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';

//Styles
import * as styles from './GoToTop.module.scss';

const GoToTop = () => {
    const [show, setShow] = useState(false);

    const scrollUp = () => {
        if (typeof window !== 'undefined') {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
    let timeout = null;
    const checkHeight = useCallback(() => {
        if (typeof window !== 'undefined') {
            if(timeout){
            window.clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
               
                if (window.scrollY > 1000 && !show) {
                    setShow(true);

                } 
                else{
                    setShow(false)
                }
            
            }, 100)
        }
    }, [])
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', checkHeight)
            return ()=>{window.removeEventListener('scroll', checkHeight)}
        }
    }, [checkHeight]) 

    return (
        <div className={`${styles.GoToTop} ${show ? styles.show : ''}`} onClick={scrollUp}>
            <div className={styles.arrowWrapper}>
                <div></div>
                <div></div>
            </div>
            <p>TO TOP</p>
        </div>
    )
}

export default GoToTop;